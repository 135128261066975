/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~sanitize.css/sanitize.css";

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

body.fontLoaded {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

app-root {
  background-color: #fafafa;
  min-height: 100%;
  min-width: 100%;
}


mat-icon {
  display: inline-flex;
  vertical-align: middle;
}
